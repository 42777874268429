<template>
    <div :class="{block: showingNavigationDropdown, hidden: !showingNavigationDropdown}" class="sm:hidden">
        <div class="pt-2 pb-3 space-y-1">
            <!-- Public Pages -->
            <ResponsiveNavLink
                v-if="$page.props.hasPayments"
                :href="route('pricing.index')"
                :active="route().current('pricing.index')">
                {{ $t('frontend.pricing.title') }}
            </ResponsiveNavLink>
            <ResponsiveNavLink :href="route('contacts.index')" :active="route().current('contacts.index')">
                {{ $t('frontend.contact.title') }}
            </ResponsiveNavLink>
            <!-- Authenticated Pages -->
            <template v-if="$page.props.canLogin">
                <template v-if="$authenticated()">
                    <ResponsiveNavLink
                        v-if="$role('admin')"
                        :href="route('admin.dashboard')"
                        :active="route().current('admin.dashboard')">
                        {{ $t('backend.back_office') }}
                    </ResponsiveNavLink>
                </template>
                <template v-else>
                    <ResponsiveNavLink :href="route('login')" :active="route().current('login')">
                        {{ $t('Log in') }}
                    </ResponsiveNavLink>
                    <ResponsiveNavLink
                        v-if="$page.props.canRegister"
                        :href="route('register')"
                        :active="route().current('register')">
                        {{ $t('Register') }}
                    </ResponsiveNavLink>
                </template>
            </template>
        </div>

        <!-- Responsive Settings Options -->
        <div v-if="$page.props?.auth?.user" class="pt-4 pb-1 border-t border-gray-200 dark:border-gray-600">
            <div class="flex items-center px-4">
                <div v-if="$page.props?.jetstream?.managesProfilePhotos" class="shrink-0 me-3">
                    <img
                        class="h-10 w-10 rounded-full object-cover"
                        :src="$page.props?.auth?.user?.profile_photo_url"
                        :alt="$page.props?.auth?.user?.name" />
                </div>

                <div>
                    <div class="font-medium text-base text-gray-800 dark:text-gray-200">
                        {{ $page.props?.auth?.user?.name }}
                    </div>
                    <div class="font-medium text-sm text-gray-500">
                        {{ $page.props?.auth?.user?.email }}
                    </div>
                </div>
            </div>

            <div class="mt-3 space-y-1">
                <ResponsiveNavLink :href="route('admin.profile.show')" :active="route().current('admin.profile.show')">
                    {{ $t('Profile') }}
                </ResponsiveNavLink>

                <ResponsiveNavLink
                    v-if="$page.props?.hasPayments"
                    :href="route('billing.index')"
                    :active="route().current('billing.index')">
                    {{ $t('payments.billing.title') }}
                </ResponsiveNavLink>

                <!-- Authentication -->
                <form method="POST" @submit.prevent="logout">
                    <ResponsiveNavLink as="button">{{ $t('Log Out') }}</ResponsiveNavLink>
                </form>
            </div>
        </div>
    </div>
</template>
<script setup>
import {ref} from 'vue';
import {usePage} from '@inertiajs/vue3';
import {ResponsiveNavLink} from '@/Components';

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
});

const $page = usePage();

const showingNavigationDropdown = ref(props.show);

const emit = defineEmits(['switch-to-team', 'logout']);

const switchNavigationDropdown = () => {
    showingNavigationDropdown.value = !showingNavigationDropdown.value;
};

const logout = () => {
    emit('logout');
};

defineExpose({switchNavigationDropdown});
</script>
