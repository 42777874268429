<template>
    <nav class="bg-base-300 border-b border-gray-100 dark:border-gray-700 sticky top-0 z-50">
        <!-- Primary Navigation Menu -->
        <DesktopFrontendNavMenu
            :show="showingNavigationDropdown"
            @logout="router.post(route('logout'))"
            @switch-navigation-dropdown="switchNavigationDropdown" />

        <!-- Responsive Navigation Menu -->
        <MobileFrontendNavMenu
            ref="mobileMenu"
            :show="showingNavigationDropdown"
            @logout="router.post(route('logout'))" />
    </nav>
</template>
<script setup>
import {ref} from 'vue';
import {router} from '@inertiajs/vue3';
import {DesktopFrontendNavMenu, MobileFrontendNavMenu} from '@/Layouts/Partials/Frontend';

const showingNavigationDropdown = ref(false);

const mobileMenu = ref(null);

const switchNavigationDropdown = () => {
    showingNavigationDropdown.value = !showingNavigationDropdown.value;
    mobileMenu.value?.switchNavigationDropdown();
};
</script>
