<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
            <div class="flex">
                <!-- Logo -->
                <div class="shrink-0 flex items-center">
                    <InertiaLink :href="route('home')" :aria-label="$t('Home')">
                        <ApplicationMark class="block h-9 w-auto" />
                    </InertiaLink>
                </div>

                <!-- Navigation Links -->
                <div class="hidden space-x-8 sm:-my-px sm:ms-10 sm:flex">
                    <!-- Public Pages -->
                    <NavLink
                        v-if="$page.props.hasPayments"
                        :href="route('pricing.index')"
                        :active="route().current('pricing.index')">
                        {{ $t('frontend.pricing.title') }}
                    </NavLink>
                    <NavLink :href="route('contacts.index')" :active="route().current('contacts.index')">
                        {{ $t('frontend.contact.title') }}
                    </NavLink>
                </div>
            </div>

            <div class="hidden sm:flex sm:items-center sm:ms-6">
                <div v-if="$page.props.canLogin" class="space-x-3 mr-5 h-16 flex">
                    <!-- Authenticated Pages -->
                    <template v-if="$authenticated()">
                        <!-- Dashboard (Admins only) -->
                        <NavLink
                            v-if="$role('admin')"
                            :href="route('admin.dashboard')"
                            :active="route().current('admin.dashboard')">
                            {{ $t('backend.back_office') }}
                        </NavLink>
                    </template>
                    <template v-else>
                        <NavLink :href="route('login')" :active="route().current('login')">
                            {{ $t('Log in') }}
                        </NavLink>
                        <NavLink
                            v-if="$page.props.canRegister"
                            :href="route('register')"
                            :active="route().current('register')">
                            {{ $t('Register') }}
                        </NavLink>
                    </template>
                </div>
                <!-- Locale Switcher -->
                <LocaleSwitcher class="mr-3" />

                <!-- Cart -->
                <InertiaLink
                    v-if="$page.props.hasPayments && $authenticated()"
                    :href="route('payments.cart.index')"
                    class="h-6">
                    <div class="indicator">
                        <span v-if="$page.props.cartItemsCount" class="indicator-item badge badge-secondary badge-xs">{{
                            $page.props.cartItemsCount
                        }}</span>
                        <div class="w-6 h-6">
                            <ShoppingCartIcon :alt="$t('payments.cart.title')" class="text-primary" />
                        </div>
                    </div>
                </InertiaLink>

                <!-- User Dropdown -->
                <div class="ms-3 relative">
                    <Dropdown align="right" width="48">
                        <template v-if="$page.props?.auth?.user" #trigger>
                            <button
                                v-if="$page.props?.jetstream?.managesProfilePhotos"
                                class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition">
                                <img
                                    class="h-8 w-8 rounded-full object-cover"
                                    :src="$page.props?.auth?.user?.profile_photo_url"
                                    :alt="$page.props?.auth?.user?.name" />
                            </button>

                            <span v-else class="inline-flex rounded-md">
                                <button
                                    type="button"
                                    class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-800 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none focus:bg-gray-50 dark:focus:bg-gray-700 active:bg-gray-50 dark:active:bg-gray-700 transition ease-in-out duration-150">
                                    {{ $page.props?.auth?.user?.name }}

                                    <svg
                                        class="ms-2 -me-0.5 h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                </button>
                            </span>
                        </template>

                        <template #content>
                            <!-- Account Management -->
                            <div class="block px-4 py-2 text-xs text-gray-400">
                                {{ $t('Manage Account') }}
                            </div>

                            <DropdownLink :href="route('admin.profile.show')">{{ $t('Profile') }}</DropdownLink>

                            <DropdownLink v-if="$page.props?.hasPayments" :href="route('billing.index')"
                                >{{ $t('payments.billing.title') }}
                            </DropdownLink>

                            <div class="border-t border-gray-200 dark:border-gray-600" />

                            <!-- Authentication -->
                            <form @submit.prevent="logout">
                                <DropdownLink as="button">{{ $t('Log Out') }}</DropdownLink>
                            </form>
                        </template>
                    </Dropdown>
                </div>
            </div>

            <!-- Mobile Hamburger -->
            <div class="-me-2 flex items-center sm:hidden">
                <!-- Mobile Locale Switcher -->
                <!-- @todo: Change to MobileLocaleSwitcher with flat dropdown menu -->
                <LocaleSwitcher class="mr-3" />
                <button
                    :aria-label="$t('Menu')"
                    class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 dark:text-gray-500 hover:text-gray-500 dark:hover:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-900 focus:outline-none focus:bg-gray-100 dark:focus:bg-gray-900 focus:text-gray-500 dark:focus:text-gray-400 transition duration-150 ease-in-out"
                    @click="onNavigationDropdownClick">
                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                        <path
                            :class="{
                                hidden: showingNavigationDropdown,
                                'inline-flex': !showingNavigationDropdown,
                            }"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16" />
                        <path
                            :class="{
                                hidden: !showingNavigationDropdown,
                                'inline-flex': showingNavigationDropdown,
                            }"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
import {ref} from 'vue';
import {Link as InertiaLink, usePage} from '@inertiajs/vue3';
import {Dropdown, DropdownLink, NavLink} from '@/Components';
import {LocaleSwitcher} from '@/Components/Theme';
import ApplicationMark from '@/images/mark.svg';
import {ShoppingCartIcon} from '@heroicons/vue/24/solid/index.js';

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
});

const $page = usePage();

const showingNavigationDropdown = ref(props.show);

const emit = defineEmits(['switch-navigation-dropdown', 'switch-to-team', 'logout']);

const onNavigationDropdownClick = () => {
    // This will change hamburger menu icon
    showingNavigationDropdown.value = !showingNavigationDropdown.value;
    emit('switch-navigation-dropdown', showingNavigationDropdown.value);
};

const logout = () => {
    emit('logout');
};
</script>
